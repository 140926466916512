import React from 'react';
import SVGParser from '../../components/SVGParser';

const DataProtectionHelper = ({ section }) => {
  return (
    <div className="data-protection__section">
      <SVGParser classes="data-protection__icon" name={section.icon} data-sal="slide" />
      <div className="data-protection__section-heading" data-sal="slide">
        {section.sectionHeading}
      </div>
      <div className="data-protection__section-subheading" data-sal="slide">
        {section.sectionSubheading}
      </div>
    </div>
  );
};

export default DataProtectionHelper;
