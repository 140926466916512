import React, { useEffect } from 'react';
import './DayNight.scss';
import HTMLReactParser from 'html-react-parser';
const DayNightMobile = ({ title1, text1, video1, video2, content2 }) => {
  return (
    <div className="day-night">
      <div className="day-night__cards">
        <div className="day-night__text-wrapper">
          <div className="container">
            <div className="day-night__video">
              <video autoPlay playsInline muted preload="metadata" loop>
                <source src={video1.localFile.publicURL} />
              </video>
            </div>
            <div className="day-night__text-inner">
              <div className="day-night__title">{HTMLReactParser(title1)}</div>
              <div className="day-night__text">{HTMLReactParser(text1)}</div>
            </div>
          </div>
        </div>

        <div className="day-night__text-wrapper">
          <div className="container">
            <div className="day-night__video">
              <video autoPlay playsInline muted preload="metadata" loop>
                <source src={video2.localFile.publicURL} />
              </video>
            </div>

            {content2.map((item, i) => (
              <div className="day-night__text-inner" key={i}>
                <div className="day-night__title">{HTMLReactParser(item.title)}</div>
                <div className="day-night__text">{HTMLReactParser(item.text)}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayNightMobile;
