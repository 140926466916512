import React from 'react';
import DataProtectionHelper from './DataProtectionHelper';
import './DataProtection.scss';

const AppDownload = ({ ACFBlockDataProtection: { heading, subheading, sections } }) => {
  return (
    <div className="data-protection">
      <div className="container">
        <div className="data-protection__wrapper">
          <div className="data-protection__heading" data-sal="slide">
            {heading}
          </div>
          <div className="data-protection__subheading" data-sal="slide">
            {subheading}
          </div>
          {sections.map((section, index) => (
            <DataProtectionHelper section={section} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppDownload;
