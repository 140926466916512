import React from 'react';
import Button from '../Button/Button';
import SVGParser from '../../components/SVGParser';

const AppDownloadLinks = (props) => {
  return (
    <div>
      {props.link && (
        <div className="app-download__button-wrapper" data-sal={props.md ? 'slide' : ''}>
          <Button link={props.link.url} buttonText={props.link.title} className="button is--primary is--xl" />
        </div>
      )}
      <div className="app-download__store" data-sal={props.md ? 'slide' : ''}>
        <a href={props.appStoreLink}>
          <SVGParser name="app-store-icon" />
        </a>
        <a className="app-download__google" href={props.playStoreLink}>
          <SVGParser name="play-store-icon" />
        </a>
      </div>
    </div>
  );
};

export default AppDownloadLinks;
