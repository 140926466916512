import React from 'react';
import BigHeadline from '../blocks/BigHeadline/BigHeadline';
import Specifications from '../blocks/Specifications/Specifications';
import DataProtection from '../blocks/DataProtection/DataProtection';
import AppDownload from '../blocks/AppDownload/AppDownload';
import CTA from '../blocks/CTA/CTA';
import ExplosionAnimation from '../blocks/ExplosionAnimation/LoadableExplosionAnimation';
import CTA2 from '../blocks/CTA2/CTA2';
import CeilingAnimation from '../blocks/CeilingAnimation/LoadableCeilingAnimation';
import DataFaqs from '../blocks/DataFaqs/DataFaqs';
import Teaser from '../blocks/Teaser/Teaser';
import DayNight from '../blocks/DayNight/DayNight';
import CookieTeaser from '../blocks/CookieTeaser/CookieTeaser';
import Cookie from '../blocks/Cookie/Cookie';
import PostsSlider from '../blocks/PostsSlider/PostsSlider';
import config from '../../config';
import contentParser from 'gatsby-wpgraphql-inline-images';

const components = {
  ACFBlockCeilingAnimation: CeilingAnimation,
  ACFBlockExplosionAnimation: ExplosionAnimation,
  ACFBlockBigHeadline: BigHeadline,
  ACFBlockSpecifications: Specifications,
  ACFBlockCTA2: CTA2,
  ACFBlockDataProtection: DataProtection,
  ACFBlockAppDownload: AppDownload,
  ACFBlockCTA: CTA,
  ACFBlockDataFaqs: DataFaqs,
  ACFBlockTeaser: Teaser,
  ACFBlockDayNight: DayNight,
  ACFCookieTeaser: CookieTeaser,
  ACFCookieBlock: Cookie,
  ACFPostsSlider: PostsSlider,
};

const convertACFProps = (component) => {
  // get all object keys and iterate over them
  Object.keys(component).forEach((key) => {
    if (key.includes('Fields')) {
      component.data = component[key];
      delete component[key];
    }
  });

  return component;
};

const ComponentParser = (props) => {
  let { content } = props;
  if (!content) return null;
  const filteredComponents = content.filter(
    (component) => !component || !(Object.keys(component).length === 0 && component.constructor === Object)
  );
  if (filteredComponents && filteredComponents.length > 0) {
    const pageComponents = filteredComponents.map((component, index) => {
      const Component = components[Object.keys(component)[0]];
      if (!Component)
        return (
          <div className="container" key={index}>
            <div className="block">
              {contentParser({ content: component.originalContent }, config.inlineImagesOptions)}
            </div>
          </div>
        );

      component = convertACFProps(component);
      return <Component key={index} {...component} {...component.data} {...component.attributes} />;
    });

    if (pageComponents) {
      return pageComponents;
    }
  }
  return null;
};

export default ComponentParser;
