import React, { useState, useEffect, useContext, useRef } from 'react';
import './Teaser.scss';
import Image from '../../components/Image/Image';
import Button from '../../components/Button/Button';
import VideoOverlay from '../../components/VideoOverlay/VideoOverlay';
import videoFinishedContext from '../../hooks/useContext';
import SVGParser from '../../components/SVGParser';

const Teaser = ({ ACFBlockTeaser: { video, mobileVideo, title, image, subtitle, text, button, youtubeVideoId } }) => {
  const { videoFinished, setVideoFinished } = useContext(videoFinishedContext);
  const [videoStarted, setVideoStarted] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [hideVideo, setHideVideo] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [videoDelayed, setVideoDelayed] = useState(false);
  const [videoURL, setVideoURL] = useState(false);
  const videoFinishDelay = 2500;
  const videoLoadingDelay = 4000;
  const videoRef = useRef(null);

  const startVideo = (event) => {
    event.preventDefault();
    setShowOverlay(true);
    document.body.classList.add('no-scroll-overlay');
  };

  useEffect(() => {
    if (!loaded) {
      document.body.classList.add('no-scroll');
      setLoaded(true);
    }

    if (videoFinished) {
      document.body.classList.remove('no-scroll');
    }

    const timerVideoStarted = setTimeout(() => {
      if (!videoStarted) {
        document.body.classList.remove('no-scroll');
        setHideVideo(true);

        if (videoRef && videoRef.current) {
          videoRef.current.pause();
          videoRef.current.src = '';
          videoRef.current.load();
        }
      }
    }, videoFinishDelay);

    const timerVideoLoading = setTimeout(() => {
      if (!videoFinished) {
        setVideoDelayed(true);
      }
    }, videoLoadingDelay);

    setVideoURL(
      typeof window !== 'undefined' && window.innerWidth < 768
        ? mobileVideo.localFile.publicURL
        : video.localFile.publicURL
    );

    return () => {
      clearTimeout(timerVideoStarted);
      clearTimeout(timerVideoLoading);
    };
  });

  return (
    <>
      <div
        className={`teaser__video container-outer ${videoFinished || hideVideo ? 'is--hidden' : ''} ${
          videoStarted ? 'is--not-loading' : ''
        }`}
      >
        <div className="teaser__logo"></div>
        {videoURL && (
          <video
            autoPlay
            playsInline
            muted
            preload="metadata"
            onEnded={() => setVideoFinished(true)}
            onPlay={() => setVideoStarted(true)}
            ref={videoRef}
          >
            <source src={!hideVideo ? videoURL : ''} type="video/mp4" />
          </video>
        )}
        {videoDelayed && (
          <button className="teaser__close" onClick={() => setHideVideo(true)}>
            <SVGParser name="video-overlay-close" />
          </button>
        )}
      </div>
      <div className="teaser-wrap">
        <div className="teaser">
          <div className="teaser__inner">
            <div className="container">
              <h1 className={`teaser__headline slide ${videoFinished || hideVideo ? 'is--animated' : ''}`}>{title}</h1>

              <div className="teaser__image">
                <div data-sal="slide">
                  <Image image={image} />
                </div>
              </div>
            </div>
          </div>

          <div className="teaser__content">
            <div className="container">
              <h2 className="teaser__subtitle" data-sal="slide">
                {subtitle}
              </h2>
              <div className="teaser__text" data-sal="slide">
                {text}
              </div>
              {button && (
                <div data-sal="slide">
                  <Button className="button is--xl is--primary" link="#video-overlay" onClick={startVideo}>
                    {button.title}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <VideoOverlay showOverlay={showOverlay} setShowOverlay={setShowOverlay} youtubeVideoId={youtubeVideoId} />
    </>
  );
};

export default Teaser;
