import React from 'react';
import './Specifications.scss';
import SpecificationsHelper from './SpecificationsHelper';

const Specifications = ({ ACFBlockSpecifications: { specifications } }) => {
  return (
    <div className="specs">
      <div className="container">
        <div className="specs__wrapper">
          {specifications.map((specification, index) => (
            <SpecificationsHelper specification={specification} index={index} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Specifications;
