import React, { useState, useEffect } from 'react';
import './VideoOverlay.scss';
import SVGParser from '../SVGParser';
import YouTube from 'react-youtube';
const VideoOverlay = (props) => {
  const { showOverlay, setShowOverlay, youtubeVideoId } = props;
  const [player, setPlayer] = useState(null);

  const endVideo = () => {
    setShowOverlay(false);
    document.body.classList.remove('no-scroll-overlay');
    player.stopVideo();
  };

  const opts = {
    playerVars: {
      autoplay: 0,
      rel: 0,
    },
  };

  const storeEvent = (event) => {
    setPlayer(event.target);
  };

  useEffect(() => {
    if (showOverlay) {
      player.playVideo();
    }
  });

  return (
    <div className={`video-overlay ${showOverlay ? 'is--active' : ''}`}>
      <div className="container">
        <a className="video-overlay__close-btn" onClick={endVideo}>
          <SVGParser name="video-overlay-close" />
        </a>
        <YouTube
          id="livy-video-overlay"
          videoId={youtubeVideoId}
          className="video-overlay__video"
          opts={opts}
          onEnd={endVideo}
          onReady={storeEvent}
        />
      </div>
    </div>
  );
};

export default VideoOverlay;
