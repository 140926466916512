import React from 'react';
import './BigHeadline.scss';

const BigHeadline = ({ ACFBlockBigHeadline: { headline } }) => {
  return (
    <div className="big-headline">
      <div className="container">
        <div className="big-headline__wrapper">
          <h2 className="big-headline__heading" data-sal="slide">
            {headline}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default BigHeadline;
