import React, { useRef } from 'react';
import SVGParser from '../../components/SVGParser';

const SectionsHelper = ({ section }) => {
  return (
    <div className="specs__section-wrapper" data-sal="slide">
      <div className="specs__icon-wrapper">
        <SVGParser name={section.icon} className="specs__icon" />
      </div>
      <div>
        <div className="specs__section-heading">{section.iconHeading}</div>
        <div className="specs__section-subheading">{section.iconSubheading}</div>
      </div>
    </div>
  );
};

export default SectionsHelper;
