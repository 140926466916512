import React, { useEffect, useState } from 'react';
import './Cookie.scss';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import CookiesObject from 'universal-cookie';
import CookieHelper from './CookieHelper';
import config from '../../../config';
import { navigate } from 'gatsby';

const Cookie = ({ ACFCookieBlock: { cookies } }) => {
  const cookiesObj = new CookiesObject();
  const isBrowser = typeof window !== 'undefined';
  const location = useLocation();
  if (isBrowser) {
    initializeAndTrack(location);
  }
  const [cookiesNameArray, setCookiesNameArray] = useState({});

  useEffect(() => {
    cookies.forEach((cookieElment) => {
      const obj = {};
      obj[cookieElment.cookieName] = cookiesObj.get(cookieElment.cookieName) == 'true' ? true : false;
      setCookiesNameArray((prevObject) => ({ ...prevObject, ...obj }));
    });
  }, []);
  const handleSubmit = () => {
    for (const property in cookiesNameArray) {
      cookiesObj.set(
        property,
        property === 'allowFunctional' ? 'true' : cookiesNameArray[property] == true ? 'true' : 'false',
        config.cookieSettings
      );
    }

    navigate(-1);
  };

  return (
    <div className="cookie">
      <div className="container">
        <div className="cookie__wrapper">
          {cookies &&
            cookies.map((cookie, i) => (
              <CookieHelper key={i} cookie={cookie} object={cookiesNameArray} setObject={setCookiesNameArray} />
            ))}
          <div className="cookie__button-wrapper">
            <div onClick={() => handleSubmit()} className={'button is--primary cookie__button'}>
              Auswahl speichern
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookie;
