import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './AppDownload.scss';
import ParseHTML from 'html-react-parser';
import Frame from '../../images/phone-frame@2x.png';
import AppDownloadLinks from '../../components/AppDownloadLinks/AppDownloadLinks';

const AppDownload = ({ ACFBlockAppDownload: { heading, features, link, images } }) => {
  const [index, setIndex] = useState(0);
  const breakpoints = useBreakpoint();

  useEffect(() => {
    if (images) {
      const interval = setInterval(() => {
        setIndex(index < images.length - 1 ? index + 1 : 0);
      }, 6000);
      return () => clearInterval(interval);
    }
  });

  const { wp } = useStaticQuery(
    graphql`
      {
        wp {
          themeOptions {
            ACFThemePageLink {
              appleStoreLink
              googlePlayStoreLink
            }
          }
        }
      }
    `
  );

  const themeOptions = wp.themeOptions.ACFThemePageLink;

  return (
    <div className="app-download">
      <div className="container">
        <div className="app-download__wrapper">
          <h3 className="app-download__mobile-heading h-hidden-sm-up" data-sal="slide">
            {ParseHTML(heading)}
          </h3>
          <div className="app-download__content-wrapper">
            <div className="app-download__feature-block-wrapper">
              <h3 className="app-download__heading h-hidden-sm" data-sal="slide">
                {ParseHTML(heading)}
              </h3>
              <div className="app-download__feature-wrapper">
                {features &&
                  features.map((feature, i) => (
                    <h4 key={i} className={`app-download__feature-heading-fade ${index == i ? 'is--active' : ''}`}>
                      {feature.heading}
                    </h4>
                  ))}
                {features &&
                  !breakpoints.sm &&
                  features.map((feature, i) => (
                    <div key={i} className={`app-download__feature-text-fade ${index == i ? 'is--active' : ''}`}>
                      {feature.text}
                    </div>
                  ))}
              </div>
              {!breakpoints.md && (
                <AppDownloadLinks
                  link={link}
                  appStoreLink={themeOptions.appleStoreLink}
                  playStoreLink={themeOptions.googlePlayStoreLink}
                  md={true}
                />
              )}
            </div>
            <div className="app-download__mobile-wrapper">
              <img src={Frame} className="app-download__frame" width="300" />
              <div className="app-download__screen">
                <div>
                  {images &&
                    images.map((element, i) => (
                      <img
                        src={element?.image?.localFile?.publicURL}
                        key={i}
                        className={`app-download__mobile-fade ${index == i ? 'is--active' : ''} `}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
          {breakpoints.md && (
            <AppDownloadLinks
              link={link}
              appStoreLink={themeOptions.appleStoreLink}
              playStoreLink={themeOptions.googlePlayStoreLink}
              md={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppDownload;
