import React, { useState } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../layout';
import ComponentParser from '../ComponentParser';
import videoFinishedContext from '../../hooks/useContext';
import langContext from '../../hooks/langContext';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import getLocalFromCode from '../../utils/locales';

Bugsnag.start({
  apiKey: '54f3286133c20a3466a4335d842df71a',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.ENV,
  enabledReleaseStages: ['production', 'staging'],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const Page = ({ data }) => {
  const { wpPage: page } = data;
  const [videoFinished, setVideoFinished] = useState(false);
  const [lang, setLang] = useState(getLocalFromCode(page.locale.locale));
  return (
    <ErrorBoundary>
      <langContext.Provider value={{ lang, setLang }}>
        <videoFinishedContext.Provider value={{ videoFinished, setVideoFinished }}>
          <Layout page={page}>
            <Seo post={page} />
            <div className="page">
              {!!page.blocks && (
                <div className="page-content">
                  <ComponentParser content={page.blocks} />
                </div>
              )}
            </div>
          </Layout>
        </videoFinishedContext.Provider>
      </langContext.Provider>
    </ErrorBoundary>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;

export const pageQuery = graphql`
  query PageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      uri
      nodeType
      isFrontPage
      locale {
        locale
      }
      translated {
        slug
        isFrontPage
        locale {
          locale
        }
      }
      blocks {
        ... on WpAcfCeilingAnimationBlock {
          ACFBlockCeilingAnimation {
            headlines {
              text
            }
          }
        }
        ... on WpAcfExplosionAnimationBlock {
          ACFBlockExplosionAnimation {
            explosionImages {
              altText
            }
          }
        }
        ... on WpAcfPostsSliderBlock {
          ACFPostsSlider {
            items {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 700, height: 700)
                  }
                  extension
                  publicURL
                }
              }
              title
              link {
                url
                title
              }
            }
          }
        }
        ... on WpAcfCtaBlock {
          ACFBlockCTA {
            heading
            link {
              url
              title
            }
          }
        }
        ... on WpAcfCta2Block {
          ACFBlockCTA2 {
            firstHeading
            firstSubheading
            secondHeading
            secondSubheading
            text
            links {
              link {
                url
                title
              }
            }
            button {
              url
              title
            }
          }
        }
        ... on WpAcfAppDownloadBlock {
          ACFBlockAppDownload {
            link {
              url
              title
            }
            heading
            features {
              heading
              text
            }
            images {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  extension
                  publicURL
                }
              }
            }
          }
        }
        ... on WpAcfBigHeadlineBlock {
          ACFBlockBigHeadline {
            headline
          }
        }
        ... on WpAcfSpecificationsBlock {
          ACFBlockSpecifications {
            specifications {
              subheading
              text
              heading
              icons {
                icon
                iconHeading
                iconSubheading
              }
              video {
                localFile {
                  extension
                  publicURL
                }
                title
              }
            }
          }
        }
        ... on WpAcfDayNightBlock {
          ACFBlockDayNight {
            text1
            title1
            video1 {
              localFile {
                extension
                publicURL
              }
            }
            video2 {
              localFile {
                extension
                publicURL
              }
            }
            content2 {
              text
              title
            }
          }
        }
        ... on WpAcfTeaserBlock {
          ACFBlockTeaser {
            title
            subtitle
            text
            button {
              title
              url
            }
            youtubeVideoId
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 560, width: 560)
                }
                extension
                publicURL
              }
            }
            video {
              mediaItemUrl
              mimeType
              localFile {
                extension
                publicURL
              }
            }
            mobileVideo {
              mediaItemUrl
              mimeType
              localFile {
                extension
                publicURL
              }
            }
          }
        }
        ... on WpAcfDataProtectionBlock {
          ACFBlockDataProtection {
            heading
            subheading
            sections {
              icon
              sectionHeading
              sectionSubheading
            }
          }
        }
        ... on WpAcfDataFaqsBlock {
          ACFBlockDataFaqs {
            collapses {
              tabs {
                images {
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                      extension
                      publicURL
                    }
                  }
                }
                text
                title
              }
              title
              isFaq
              link {
                url
                title
              }
            }
          }
        }
        ... on WpAcfCookieTeaserBlock {
          ACFCookieTeaser {
            link {
              title
              url
            }
            text
            title
          }
        }
        ... on WpAcfCookieBlock {
          ACFCookieBlock {
            cookies {
              text
              heading
              expandButton
              cookieName
              cookies {
                holder
                name
                retention
                serviceName
                text
              }
            }
          }
        }
        originalContent
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`;
