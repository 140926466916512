import React, { Children } from 'react';
import './Button.scss';

const Button = (props) => {
  return (
    <a href={props.link} className={props.className} onClick={props.onClick}>
      {props.buttonText}
      {props.children}
    </a>
  );
};

export default Button;
