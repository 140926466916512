import React from 'react';
import DataAndFaqsHelper from './DataFaqsHelper';
import './DataFaqs.scss';

const DataAndFaqs = ({ ACFBlockDataFaqs: { collapses } }) => {
  return (
    <div className="data-faqs">
      <div className="container">
        {collapses.map((collapse, index) => (
          <DataAndFaqsHelper collapse={collapse} key={index} />
        ))}
      </div>
    </div>
  );
};

export default DataAndFaqs;
