import React from 'react';
import './CookieTeaser.scss';
import UniversalLink from '../../components/UniversalLink';

const CookieTeaser = ({ ACFCookieTeaser }) => {
  return (
    <div className="cookie-teaser">
      <div className="container">
        <div className="cookie-teaser__wrapper">
          <div className="cookie-teaser__title">{ACFCookieTeaser.title}</div>
          <div className="cookie-teaser__text">{ACFCookieTeaser.text}</div>
          {ACFCookieTeaser.link && (
            <UniversalLink to={ACFCookieTeaser.link.url} className="cookie-teaser__link">
              {ACFCookieTeaser.link.title}
            </UniversalLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default CookieTeaser;
