import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import HTMLReactParser from 'html-react-parser';
import Image from '../../components/Image/Image';
import UniversalLink from '../../components/UniversalLink';

const DataAndFaqsHelper = ({ collapse }) => {
  const [expand, setExpanded] = useState(false);

  return (
    <div className={`data-faqs__wrapper ${collapse.isFaq ? 'is--faq' : ''}`}>
      <div className={`data-faqs__heading ${expand ? 'is--expanded' : ''}`} onClick={() => setExpanded(!expand)}>
        {collapse.title}
      </div>
      <Collapse isOpened={expand}>
        <div className="data-faqs__tabs">
          {collapse.tabs.map((tab, index) => (
            <div key={index} className="data-faqs__tab">
              <div className="data-faqs__tab-title">{tab.title}</div>
              <div className="data-faqs__tab-text-images-wrapper">
                <div className="data-faqs__tab-text">{HTMLReactParser(tab.text)}</div>
                {tab.images && (
                  <div className="data-faqs__tab-images">
                    {tab.images?.map((image, index) => (
                      <div key={index} className="data-faqs__tab-image-wrapper">
                        <Image image={image.image} classes="data-faqs__tab-image" />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
          {collapse.isFaq && (
            <div className="data-faqs__tab">
              <UniversalLink
                to={collapse.link.url}
                className="data-faqs__button button is--secondary is--xl has--arrow"
              >
                {collapse.link.title}
              </UniversalLink>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default DataAndFaqsHelper;
