import React, { useRef, useEffect, useState } from 'react';
import ParseHTML from 'html-react-parser';
import SectionsHelper from './SectionsHelper';

const SpecificationsHelper = ({ specification, index }) => {
  const vidRef = useRef(null);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [hasFinished, setHasFinished] = useState(false);

  const videoOffset = 200;

  const handlePlayVideo = async () => {
    if (vidRef.current) await vidRef.current.play();
  };

  useEffect(() => {
    const onScroll = () => {
      if (vidRef && vidRef.current) {
        if (vidRef.current.getBoundingClientRect().top + videoOffset < window.innerHeight && !hasPlayed) {
          setHasPlayed(true);
          vidRef.current.play();
        }
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  return (
    <div className={`specs__block-wrapper ${index % 2 === 0 ? 'is--reverse' : ''}`}>
      {specification.video && (
        <div className="specs__video-wrapper" data-sal="fade-in">
          <video ref={vidRef} muted playsInline className="specs__video" onEnded={() => setHasFinished(true)}>
            <source src={specification.video.localFile.publicURL} />
          </video>
          <div
            className={`play-button animate fade-in ${hasFinished ? 'is--animated' : ''}`}
            onClick={() => {
              handlePlayVideo();
            }}
          ></div>
        </div>
      )}
      <div className="specs__details-wrapper">
        <h3 className="specs__heading" data-sal="slide">
          {ParseHTML(specification.heading)}
        </h3>
        <div className="specs__content-wrapper">
          <div className="specs__subheading" data-sal="slide">
            {specification.subheading}
          </div>
          <div className="specs__icons">
            {specification.icons.map((section, index) => (
              <SectionsHelper section={section} key={index} />
            ))}
          </div>
        </div>
        {specification.text && (
          <div className="specs__text" data-sal="slide">
            {specification.text}
          </div>
        )}
      </div>
    </div>
  );
};

export default SpecificationsHelper;
