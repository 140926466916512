import React from 'react';
import './CTA.scss';
import Button from '../../components/Button/Button';

const CTA = ({ ACFBlockCTA: { heading, link } }) => {
  return (
    <div className="cta">
      <div className="container">
        <div className="cta__wrapper">
          <h2 className="cta__heading" data-sal="slide">
            {heading}
          </h2>
          {link && (
            <div data-sal="slide">
              <Button link={link.url} buttonText={link.title} className="button is--secondary is--xl has--arrow" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CTA;
