import React from 'react';
import './CTA2.scss';
import Button from '../../components/Button/Button';

const CTA2 = ({
  ACFBlockCTA2: { firstHeading, firstSubheading, text, secondHeading, secondSubheading, links, button },
}) => {
  return (
    <div className="cta2">
      <div className="container">
        <div className="cta2__wrapper">
          <div className="cta2__header-wrapper">
            <h2 className="cta2__heading" data-sal="slide">
              {firstHeading}
            </h2>
            <div className="cta2__subheading" data-sal="slide">
              {firstSubheading}
            </div>
          </div>
          <div className="cta2__header2-wrapper">
            <div className="cta2__text" data-sal="slide">
              {text}
            </div>
            <h2 className="cta2__heading" data-sal="slide">
              {secondHeading}
            </h2>
            <div className="cta2__subheading" data-sal="slide">
              {secondSubheading}
            </div>
            <div className="cta2__links-wrapper">
              {links.map((link, index) => (
                <div className="cta2__description-wrapper" key={index}>
                  <div className="cta2__description" data-sal="slide">
                    {link.link.title}
                  </div>
                </div>
              ))}
            </div>
            <div className="cta2__button-wrapper" data-sal="slide">
              <Button link={button.url} buttonText={button.title} className="button is--primary is--xl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA2;
