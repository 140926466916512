import React from 'react';
import UniversalLink from '../../components/UniversalLink';
import Slider from 'react-slick';
import HTMLReactParser from 'html-react-parser';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './PostsSlider.scss';
import SVGParser from '../../components/SVGParser';
import Image from '../../components/Image/Image';

const PostsSlider = ({ ACFPostsSlider: { items } }) => {
  function Arrow(props) {
    const { className, style, onClick } = props;
    return <SVGParser name="arrow-right-outline" classes={className} onClick={onClick} />;
  }

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="posts-slider">
      <div className="container">
        <Slider {...settings}>
          {items.map((item, i) => (
            <div className="posts-slider__item" key={i} data-sal="slide">
              <div className="posts-slider__item-inner">
                <div className="posts-slider__image">
                  <Image image={item.image} width={200} />
                </div>
                <div className="posts-slider__title">{HTMLReactParser(item.title)}</div>
                {item.link && (
                  <UniversalLink to={item.link.url} className="posts-slider__button button is--primary is--md">
                    {item.link.title}
                  </UniversalLink>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PostsSlider;
