import React, { useState, useEffect } from 'react';
import Parser from 'html-react-parser';
import Switch from 'react-switch';
import SVGParser from '../../components/SVGParser';
import contentParser from 'gatsby-wpgraphql-inline-images';
import config from '../../../config';
import { Collapse } from 'react-collapse';

const CookieHelper = ({ cookie, object, setObject }) => {
  const [show, setShow] = useState(false);
  const [expand, setExpanded] = useState(false);
  useEffect(() => {
    setShow(object[cookie.cookieName] === undefined ? false : object[cookie.cookieName]);
  });
  const handleChange = () => {
    object[cookie.cookieName] = !object[cookie.cookieName];
    setObject({ ...object });
    setShow(!show);
  };

  return (
    <div className="cookie__one-cookie-wrapper">
      <div className="cookie__name-wrapper">
        <div className="cookie__heading">{cookie.heading}</div>
        <div className="cookie__switch-wrapper">
          <Switch
            onChange={() => handleChange()}
            checked={cookie.cookieName === 'allowFunctional' || show}
            handleDiameter={20}
            boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
            disabled={cookie.cookieName === 'allowFunctional'}
            activeBoxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
            offColor="#98A2B3"
            onColor="#5D44B7"
            onHandleColor={false}
            offHandleColor="#ffffff"
            onHandleColor="#ffffff"
            height={24}
            width={44}
            borderRadius={33}
            uncheckedHandleIcon={<SVGParser name="mobile-menu-close" classes={'cookie__icon'} />}
            uncheckedIcon={<> </>}
            checkedIcon={<> </>}
            checkedHandleIcon={<SVGParser name="check-small-bold" classes={'cookie__icon'} />}
            className="react-switch"
            id="small-radius-switch"
          />
        </div>
      </div>
      <div className="cookie__text">{Parser(cookie.text)}</div>
      <div className={`cookie__expand ${expand ? 'is--expanded' : ''}`} onClick={() => setExpanded(!expand)}>
        Cookies anzeigen
      </div>
      <Collapse isOpened={expand}>
        <div className="cookie__table-wrapper">
          <table className="cookie__table">
            {cookie.cookies &&
              cookie.cookies.map((cookieRow, i) => (
                <tbody key={i}>
                  <tr>
                    <th>Name</th>
                    <td>{cookieRow.name}</td>
                  </tr>
                  <tr>
                    <th>Inhaber</th>
                    <td>{cookieRow.holder}</td>
                  </tr>
                  <tr>
                    <th>Beschreibung</th>
                    <td>{contentParser({ content: cookieRow.text }, config.inlineImagesOptions)}</td>
                  </tr>

                  <tr>
                    <th>Domain</th>
                    <td>{cookieRow.serviceName}</td>
                  </tr>
                  <tr>
                    <th>Laufzeit</th>
                    <td>{cookieRow.retention}</td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
      </Collapse>
    </div>
  );
};

export default CookieHelper;
