import React, { useRef, useEffect } from 'react';
import './DayNight.scss';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import HTMLReactParser from 'html-react-parser';
import DayNightMobile from './DayNightMobile';
import DayNightDesktop from './DayNightDesktop';

const DayNight = ({ ACFBlockDayNight: { title1, text1, video1, video2, content2 } }) => {
  const breakpoints = useBreakpoint();
  return breakpoints.sm ? (
    <DayNightMobile title1={title1} text1={text1} video1={video1} video2={video2} content2={content2} />
  ) : (
    <DayNightDesktop title1={title1} text1={text1} video1={video1} video2={video2} content2={content2} />
  );
};

export default DayNight;
