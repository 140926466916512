import React, { useRef, useEffect, useState } from 'react';
import HTMLReactParser from 'html-react-parser';

const DayNightDesktop = ({ title1, text1, video1, video2, content2 }) => {
  const videoContainerRef = useRef(null);
  const textContainerRef = useRef(null);
  const videoInnerRef2 = useRef(null);
  const videoRef = useRef(null);
  const videoRef2 = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    if (
      videoContainerRef.current.getBoundingClientRect().top - videoContainerRef.current.getBoundingClientRect().height <
      0
    ) {
      setInView(true);
    }

    const onScroll = () => {
      if (videoContainerRef.current && textContainerRef.current) {
        const topPos = videoContainerRef.current.getBoundingClientRect().top;
        const height = videoContainerRef.current.getBoundingClientRect().height;
        const topToParent = videoInnerRef2.current.offsetTop;
        const video2Top = textContainerRef.current.getBoundingClientRect().top;
        const topPos2 = video2Top + window.innerHeight - height - topToParent;

        const video1 = videoRef.current;
        const video2 = videoRef2.current;

        if (video2Top + topToParent < window.innerHeight && video1.paused) {
          video1.play();
          video2.play();
          video1.currentTime = 0;
          video2.currentTime = 0;
        }

        if (topPos + topPos2 <= -0.5) {
          const video2Dimensions = (topPos + topPos2) / height;
          videoInnerRef2.current.style.transform = `matrix(1, 0, 0, ${
            0 <= -video2Dimensions && -video2Dimensions <= 1 ? -video2Dimensions : 1
          }, 0, 0)`;
          videoRef2.current.style.transform = `scaleY(${
            0 <= -video2Dimensions && -video2Dimensions <= 1 ? 1 / -video2Dimensions : 1
          })`;
        } else {
          videoInnerRef2.current.style.transform = `matrix(1, 0, 0, 0, 0, 0)`;
        }
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return (
    <div className="day-night">
      <div className="day-night__video-wrapper">
        <div ref={videoContainerRef} className="day-night__video-container">
          <div className="day-night__video">
            <video ref={videoRef} playsInline muted preload="auto" loop>
              <source src={video1.localFile.publicURL} />
            </video>
          </div>
          <div ref={videoInnerRef2} className="day-night__video">
            <video ref={videoRef2} playsInline muted preload="auto" loop>
              <source src={video2.localFile.publicURL} />
            </video>
          </div>
        </div>
      </div>
      <div ref={textContainerRef} className="day-night__cards">
        <div className="day-night__text-wrapper">
          <div className="container">
            <div className="day-night__text-inner">
              <div className={`day-night__title ${inView ? 'sal-animate' : ''}`} data-sal="slide">
                {HTMLReactParser(title1)}
              </div>
              <div className={`day-night__text ${inView ? 'sal-animate' : ''}`} data-sal="slide">
                {HTMLReactParser(text1)}
              </div>
            </div>
          </div>
        </div>

        <div className="day-night__text-wrapper">
          <div className="container">
            {content2.map((item, i) => (
              <div className="day-night__text-inner" key={i}>
                <div className={`day-night__title ${inView ? 'sal-animate' : ''}`} data-sal="slide">
                  {HTMLReactParser(item.title)}
                </div>
                <div className={`day-night__text ${inView ? 'sal-animate' : ''}`} data-sal="slide">
                  {HTMLReactParser(item.text)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayNightDesktop;
